import { RJSFSchema } from '../../../models/RJSFSchema';

/**
 * Establishment Services schema
 */
export const EstablishmentSchema: RJSFSchema = {
  schema: {
    type: 'object',
    title: 'Servicios de Establecimiento',
    properties: {
      Q3: {
        type: 'string',
        required: ['Q3'],
        title:
          '¿Cuántos metros cuadrados, tiene aproximadamente el área del Negocio evaluado para atención al público?',
        enum: [
          'Menos de 6 Metros Cuadrados',
          '6 Metros Cuadrados',
          'Entre 7-12 Metros Cuadrados',
          'Entre 13-20 Metros Cuadrados',
          'Más de 20 Metros Cuadrados'
        ]
      },
      Q4: {
        required: ['Q4'],
        type: 'string',
        title: 'El Negocio dispone de:',
        enum: [
          'Corresponsal Bancario',
          'Baloto',
          'Actividades Ludicas para Niños ',
          'Maquinas de juego',
          'Servicios de llamadas',
          'Servicios de lnternet o Impresión',
          'Ninguno de los Anteriores'
        ]
      },
      Q5: {
        required: ['Q5'],
        type: 'string',
        title: 'El surtido es',
        enum: ['Malo', 'Regular', 'Bueno']
      },
      Q6: {
        required: ['Q6'],
        type: 'string',
        title: 'La presentación del surtido es',
        enum: [
          'Muy Ordenada',
          'Ni Ordenada, Ni Desordenada',
          'Desordenada',
          'Ordenada'
        ]
      },
      Q7: {
        required: ['Q7'],
        type: 'string',
        title: 'El aseo es',
        enum: ['Muy Aseado', 'Ni aseado, Ni Desaseado Inferior', 'Aseado']
      },
      Q8: {
        required: ['Q8'],
        type: 'string',
        title: 'Días de la semana que atiende',
        enum: ['De Lunes a Domingo', 'De Lunes a Sábado']
      },
      Q9: {
        required: ['Q9'],
        type: 'string',
        title: 'Apartir de qué horas atiende',
        enum: [
          'A partir de las 6:00 AM',
          'Entre las 6:00-7:00 AM',
          'Entre las 7:00-8:00 AM',
          'Entre las 8:00-9:00 AM',
          'Entre las 9:00-10:00 AM',
          'A partir de las 10:00 AM'
        ]
      },
      Q10: {
        required: ['Q10'],
        type: 'string',
        title: 'Hasta qué horas atiende',
        enum: [
          'Hasta las 7:00 PM',
          'Entre las 7:00-8:00 PM',
          'Entre las 8:00-9:00 PM',
          'Entre las 9:00-10:00 PM',
          'Entre las 10:00-11:00 PM',
          'Hasta las 12:00 PM y toda la noche'
        ]
      },
      Q11: {
        required: ['Q11'],
        type: 'string',
        title: '¿Presta servicio a domicilio?',
        enum: ['Si', 'No']
      },
      Q12: {
        required: ['Q12'],
        type: 'string',
        title: '¿Cuántas personas atienden?',
        enum: [
          'Una Persona',
          'Dos Personas',
          'Tres Personas',
          'Cuatro o mas Personas'
        ]
      },
      Q13: {
        required: ['Q13'],
        type: 'string',
        title: '¿Hay congeladores diferentes?',
        enum: ['Si', 'No']
      }
    },
    required: [
      'Q3',
      'Q4',
      'Q5',
      'Q6',
      'Q7',
      'Q8',
      'Q9',
      'Q10',
      'Q11',
      'Q12',
      'Q13'
    ]
  },

  uiSchema: {
    'ui:order': [
      'Q3',
      'Q4',
      'Q5',
      'Q6',
      'Q7',
      'Q8',
      'Q9',
      'Q10',
      'Q11',
      'Q12',
      'Q13'
    ]
  }
};
