import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Messages } from '../../i18n';
import appFormStyles from '../../styles/appFormStyles';


interface AppFormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleLogout: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
}

// Actualizar atributos Cognito
async function updateUserAttributes() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const current_date = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString();
    const result = await Auth.updateUserAttributes(user, {
      "custom:Terminos_Condiciones": 'True'
    });
    const result_date = await Auth.updateUserAttributes(user, {
      "custom:Fecha_Terminos": current_date
    });
    setTimeout(() => { console.log('esperando') }, 15000)
    window.location.reload()
  } catch (err) {
    console.log(err);
  }
}

export default function AppCondition({
  handleLogout
}: AppFormProps): JSX.Element {

  const classes = appFormStyles();

  // Variables botón aceptar condiciones
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const pdf = process.env.REACT_APP_BUCKET_S3 || '';
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className={classes.header}>
          <Toolbar>
            <img
              className={classes.appBarLogo}
              src="/assets/nutresa_logo_white2.png"
              alt="logo"
            />
            <img
              className={classes.appBarLogo}
              src="/assets/cream_helado_logo.png"
              alt="logo"
              style={{ width: '45px' }}
            />
            <Typography style={{ flex: 1, fontSize: '1.5rem' }}>
              {Messages.APP_TITLE}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid className={classes.CardForm} container >
          <Grid item xs={12} md={12}>
            <div className='pdf-container'>
              <form action="" method="post">

                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.3.200/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdf} />
                  {/* <Viewer fileUrl={process.env.PUBLIC_URL + 'terms.pdf'} /> */}
                </Worker>

                <Button
                  className={classes.submittingButtonTerms}
                  size="large"
                  variant="contained"
                  onClick={handleClickOpen}>
                  He leido los términos y condiciones
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Estás de acuerdo con los términos y condiciones?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Evaluación de punto de venta
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleLogout} color="primary">
                      desacuerdo
                    </Button>
                    <Button onClick={updateUserAttributes} color="primary" autoFocus>
                      De acuerdo
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}


