import { AjvError } from '@rjsf/core';
import { ErrorObject } from 'ajv';
import localize from 'ajv-i18n';
import { toast } from 'react-toastify';
export function transformErrors(
  errors: AjvError[],
  isCurrentFormPageValid: boolean
): AjvError[] {
  const normalizedErrors = errors.map((e) => ({ ...e, keyword: e.name }));
  localize.es(normalizedErrors as unknown as ErrorObject[]);
  const isStateNotSelected = normalizedErrors.some(error => error.property === ".address.states")
  if (isCurrentFormPageValid) return [];
  /* istanbul ignore else */
  if (normalizedErrors.length > 0) {
    const errorsLength = isStateNotSelected ? (normalizedErrors.length - 6) : normalizedErrors.length
    toast.error(`Tiene ${errorsLength} campos pendientes por diligenciar, favor verifique`, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000
    })
  }
  return normalizedErrors
    .map((e) => {
      if (e.name === 'required') e.message = 'El campo es requerido';
      if (e.name === 'enum') e.message = 'Debe seleccionar alguna opción';
      return e;
    })
    .filter((e) => {
      return ['required', 'enum'].includes(e.name);
    });
}
