import moment from 'moment';
import { AppFormData } from '../../models';
import { ModelResult } from '../DataFormResult/DataResult';
import { PDFDocTitle } from './GeneratePDF';

function getPdfConfig(): {
  initialPos: number;
  currentPosition: number;
  positionYRate: number;
  positionX: number;
  positionXRate: number;
  nextColumnYPosition: number;
} {
  return {
    initialPos: 40,
    currentPosition: 40,
    positionYRate: 7,
    positionX: 12,
    positionXRate: 110,
    nextColumnYPosition: 30
  };
}

let pdfConfig = getPdfConfig();

function getNextPosition(append = 0) {
  pdfConfig.currentPosition += pdfConfig.positionYRate + append;

  return pdfConfig.currentPosition;
}

function getNextColumnPosition(append = 0) {
  pdfConfig.nextColumnYPosition += pdfConfig.positionYRate + append;
  return pdfConfig.nextColumnYPosition;
}

export default function getPdfTextData(formData: AppFormData, additionalData: ModelResult): PDFDocTitle[] {
  pdfConfig = getPdfConfig();
  return [
    {
      label: '1. Datos Generales',
      positionX: pdfConfig.positionX,
      positionY: pdfConfig.initialPos,
      fields: [
        {
          label: 'Fecha Captura:',
          value: moment().utcOffset(-5).format('YYYY-MM-DD'),
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'Consecutivo:',
          value: formData.formRequestId,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'Número de identificación:',
          value: formData.numeroIdentificacion,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'Vendedor:',
          value: formData.seller,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'Nombre del negocio:',
          value: formData.nombreNegocio,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'Razón Social del prospecto del cliente:',
          value: `${formData.razonSocialNombres} ${formData.razonSocialApellidos}`,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'Departamento del punto de venta:',
          value: `${formData.address?.cities} ${formData.address?.states}`,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition(4)
        },
        {
          label: 'Dirección del punto de venta:',
          value: `${formData.address?.fullAddress}`,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition(4)
        },
        {
          label: 'Tipo de negocio:',
          value: formData.businessType,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition(6)
        },
        {
          label: 'Años del negocio:',
          value: formData.Q2,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        }
      ]
    },
    {
      label: '2. Ubicación y alrededores',
      positionX: pdfConfig.positionX,
      positionY: getNextPosition(),
      fields: [
        {
          label:
            '¿Existen cerca (100 mts) otros negocios en donde hayan congeladores diferentes a CREM HELADO?',
          value: formData.Q14,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition()
        },
        {
          label: 'El congelador de CREM HELADO estaria ubicado:',
          value: formData.Q15,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition(10)
        },
        {
          label:
            'El consumidor puede observar directamente productos de CREM HELADO:',
          value: formData.Q16,
          positionX: pdfConfig.positionX,
          positionY: getNextPosition(4)
        }
      ]
    },
    {
      label: '3. Servicios del establecimiento',
      positionX: pdfConfig.positionXRate,
      positionY: pdfConfig.initialPos,
      fields: [
        {
          label: 'Metros Cuadrados:',
          value: formData.Q3,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition(10)
        },

        {
          label: 'El negocio dispone de:',
          value: formData.Q4,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },

        {
          label: 'El surtido es:',
          value: formData.Q5,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },

        {
          label: 'La presentación del surtido es:',
          value: formData.Q6,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },

        {
          label: 'El aseo es:',
          value: formData.Q7,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },
        {
          label: 'Días de la semana de atención:',
          value: formData.Q8,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },
        {
          label: 'Inicio de hora de atención:',
          value: formData.Q9,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },
        {
          label: 'Finalización de hora de atención:',
          value: formData.Q10,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },

        {
          label: 'Servicio a domicilio:',
          value: formData.Q11,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },

        {
          label: 'Cantidad de personas que atienden:',
          value: formData.Q12,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },

        {
          label: 'Congeladores diferentes:',
          value: formData.Q13,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        },
        {
          label: 'Scoring:',
          value: formData.dataScore?.percentageScore,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition(),
          fontStyle: 'bold'
        },
        {
          label: 'Evaluación:',
          value: `${formData.dataScore?.label}. ${additionalData.additionalData}`,
          positionX: pdfConfig.positionXRate,
          positionY: getNextColumnPosition()
        }
      ]
    }
  ];
}
