import { ObjectMap } from './Common';

export interface IAppFormMapping {
  title: string;
  answer?: string;
}

export const AppFormMapping: ObjectMap<string> = {
  razonSocialNombres: 'Registre el(los) nombre(s) de la razon social del prospecto del cliente',
  razonSocialApellidos: 'Registre el(los) apellido(s) de la razon social del prospecto del cliente',
  nombreNegocio: 'Registre el nombre del negocio',
  numeroIdentificacion: 'Registre el número de identificación',
  businessType: 'Tipo de Negocio',
  Q2: 'Años del negocio',
  Q3: '¿Cuántos metros cuadrados, tiene aproximadamente el área del Negocio evaluado para atención al público?',
  Q4: 'El Negocio dispone de:',
  Q5: 'El surtido es',
  Q6: 'La presentación del surtido es',
  Q7: 'El aseo es',
  Q8: 'Días de la semana que atiende',
  Q9: 'Apartir de qué horas atiende',
  Q10: 'Hasta qué horas atiende',
  Q11: '¿Presta servicio a domicilio?',
  Q12: '¿Cuántas personas atienden?',
  Q13: '¿Hay congeladores diferentes?',
  Q14: '¿Existen cerca (100 mts) otros negocios en donde hayan congeladores diferentes a CREM HELADO?',
  Q15: 'El congelador de CREM HELADO estaria ubicado:',
  Q16: '¿El consumidor puede observar directamente los productos de CREM HELADO?',
  address: 'Ubicación'
};
