export const Messages = {
  APP_TITLE: 'Evaluación punto de venta - Helados',
  LOGIN_APP: 'Evaluación punto de venta - Helados',
  LOGIN_FORM_EMAIL: 'Correo',
  LOGIN_FORM_PASSWORD: 'Contraseña',
  LOGIN_FORM_BUTTON: 'Iniciar sesión',
  BAD_USER_OR_PASSWORD: 'Correo y/o contraseña incorrecto',
  PAGINATION_NEXT: 'SIGUIENTE',
  PAGINATION_BACK: 'ANTERIOR',
  FORM_SUBMITTING: 'Enviando',
  FORM_SUBMIT: 'Enviar',
  EXPORT_FORM_DATA: 'Exportar Archivo',
  FORM_CARD_TITLE: 'Cuestionario'
};
