import jsPDF from 'jspdf';
import { AppFormData } from '../../models';
import { ModelResult } from '../DataFormResult/DataResult';
import getPdfTextData from './PdfTextFields';

export interface PDFDocPosition {
  label: string;
  value: string | number | undefined;
  positionX: number;
  positionY: number;
  fontStyle?: string;
}

export interface PDFDocTitle extends Omit<PDFDocPosition, 'value'> {
  fields: PDFDocPosition[];
}

export default async function GeneratePDF(
  formFields: AppFormData,
  additionalDetails: ModelResult,
  saveToLocal: boolean
): Promise<void> {
  const pdfFile = new jsPDF({ compress: true });
  const img = new Image();

  const PdfTextFields = getPdfTextData(formFields, additionalDetails);

  img.src = 'assets/nutresa_logo_1.jpg';
  pdfFile.addImage(img, 'jpg', 12, 5, 100, 20, undefined, 'FAST');

  img.src = 'assets/cream_helado_logo.png';
  pdfFile.addImage(img, 'jpg', 130, 7, 20, 20, undefined, 'FAST');

  pdfFile.setFontSize(8);

  PdfTextFields.forEach((title: PDFDocTitle) => {
    pdfFile.setFontSize(9);
    pdfFile.setFont('Arial', 'bold');

    pdfFile.text(title.label, title.positionX, title.positionY);

    pdfFile.setLineWidth(0.5);
    pdfFile.line(
      title.positionX - 2,
      title.positionY + 2,
      title.positionX + 75,
      title.positionY + 2
    );

    title.fields.forEach((field: PDFDocPosition) => {
      pdfFile.setFontSize(9);
      pdfFile.setFont('Arial', 'bold');
      pdfFile.text(`${field.label}`, field.positionX, field.positionY, {
        maxWidth: 50
      });

      pdfFile.setFontSize(8);
      pdfFile.setFont('Arial', 'normal');

      const fontStyle = field.fontStyle ? field.fontStyle : 'normal';
      pdfFile.setFont('Arial', fontStyle);

      pdfFile.text(`${field.value}`, field.positionX + 50, field.positionY, {
        maxWidth: 40
      });
    });
  });

  const sellerYValueForSign = 190;

  pdfFile.setLineWidth(0.5);
  pdfFile.text('VENDEDOR DEMO', 12, sellerYValueForSign - 2);
  pdfFile.line(10, sellerYValueForSign, 60, sellerYValueForSign);
  pdfFile.text('Nombre del Vendedor', 12, sellerYValueForSign + 3);

  pdfFile.setLineWidth(0.5);
  pdfFile.line(65, sellerYValueForSign, 105, sellerYValueForSign);
  pdfFile.text('Firma del Vendedor', 65, sellerYValueForSign + 3);

  if (saveToLocal) pdfFile.save(`${formFields.formRequestId}.pdf`);
}
