import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { ModelResultLabel } from '../../models/ModelResultLabel';

const scoreRanges: ModelResultLabel[] = [
  {
    range: {
      min: 0,
      max: 0
    },
    label: 'Establecimiento Sin Resultado, Por favor consulte con el administrador',
    iconColor: '#e82f2f',
    icon: ThumbDownIcon
  },
  {
    range: {
      min: -1,
      max: 40
    },
    label: 'Establecimiento NO APTO para la venta de helados',
    iconColor: '#e82f2f',
    icon: ThumbDownIcon
  },
  {
    range: {
      min: 40,
      max: 50
    },
    label:
      'Establecimiento a revisar detenidamente antes de instalar el congelador',
    iconColor: '#ff9400',
    icon: ReportProblemOutlinedIcon
  },
  {
    range: {
      min: 50,
      max: 100
    },
    label: 'Establecimiento MUY APTO para helados',
    iconColor: 'green',
    icon: ThumbUpIcon
  }
];

export default scoreRanges;
