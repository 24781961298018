import { AppFormData } from '../models';
import { PathwayTypeData } from '../models/PathwayTypeData';
import pathwayData from './data/pathwayType.json';
import { getCurrentDate } from './date';

export function getPathwayTypeData(): PathwayTypeData {
  return pathwayData as PathwayTypeData;
}

export function getFormRequestId(formData: AppFormData): string {
  return `${formData.sellerInfo?.sellerCode}${getCurrentDate('YYYYMMDD')}${
    formData.numeroIdentificacion
  }`;
}

function transformPathwayType(pathwayType: string): string | undefined {
  const pathwayData = getPathwayTypeData();
  const filteredType =  pathwayData.pathway_types.filter((type) => type.name === pathwayType);
  if (filteredType[0] !== undefined) {
    return filteredType[0].daneFormat;
  }
}

export function getAddress(formData: AppFormData): string {
  const updatedPathway = formData?.address?.pathwayType !== undefined ? transformPathwayType(formData?.address?.pathwayType) : formData?.address?.pathwayType;
  return `${updatedPathway} ${formData?.address?.primary} ${formData?.address?.add1} ${formData?.address?.add2}`;
}

export function getAddressWithComplement(formData: AppFormData): string {
  const updatedPathway = formData?.address?.pathwayType !== undefined ? transformPathwayType(formData?.address?.pathwayType) : formData?.address?.pathwayType;
  return `${updatedPathway} ${formData?.address?.primary} ${formData?.address?.add1} ${formData?.address?.add2} ${formData?.address?.complement}`;
}
