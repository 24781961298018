import { RJSFSchema } from '../../../models/RJSFSchema';
import {
  getRegionList,
  getRegionMapData,
  getStatesSchema
} from '../../RegionService';

/**
 * General Data schema
 */
export const GeneralDataSchema: RJSFSchema = {
  schema: {
    additionalItems: false,
    type: 'object',
    title: 'Datos Generales',
    properties: {
      razonSocialNombres: {
        type: 'string',
        title: 'Registre el(los) nombre(s) de la razon social del prospecto del cliente',
        required: ['razonSocialNombres']
      },
      razonSocialApellidos: {
        type: 'string',
        title: 'Registre el(los) apellido(s) de la razon social del prospecto del cliente',
        required: ['razonSocialApellidos']
      },
      nombreNegocio: {
        type: 'string',
        title: 'Registre el nombre del negocio',
        required: ['nombreNegocio']
      },
      numeroIdentificacion: {
        type: 'number',
        title: 'Registre el número de identificación',
        required: ['numeroIdentificacion']
      },
      businessType: {
        type: 'string',
        title: 'Tipo de Negocio',
        required: ['businessType'],
        enum: [
          'Agente Comercial',
          'Bares y Cantinas',
          'Cadenas Restaurante',
          'Cafeterías',
          'Carni/Avicola/Pescad',
          'Cash & Carry Mayoris',
          'Catering',
          'Centros de diversió',
          'Colegio/Universidad',
          'Comida Rápidas',
          'Concesiones',
          'Conveniencia Cad Nal',
          'Conveniencia Cad Reg',
          'Conveniencia Indep',
          'Cooper Farmaceuticas',
          'Distrb. Farmaceutico',
          'Drog AU de Cadenas',
          'Drog AU de Retail',
          'Drog AU Independ',
          'Drog Mostrad Indep',
          'Drog Mostrad. Caden',
          'Drog Mostrad. Retail',
          'Empresa y Of Consum',
          'Entidad de Gobierno',
          'Ferreterías/Repuesto',
          'Fruterias',
          'Fruver',
          'Grandes Panaderías',
          'Hard Discount',
          'Helad. Independiente',
          'Helad. Marca Propia',
          'Hipermercado',
          'Hoteles',
          'Kiosko/Cajonero',
          'Lácteos y Salsas',
          'Mayor. Carnes Fresca',
          'Mayorista Mixto',
          'Mayorista Snackero',
          'Merc Saludab Cad Reg',
          'Merc Saludab Indepen',
          'Minimercado Cad Reg',
          'Minimercado Independ',
          'Miscelánea o Papeler',
          'MY Com Rapi/Transf',
          'Non Food',
          'Operador Serv Indiv',
          'Otras Instituciones',
          'Otras TD Non Foods',
          'Panaderia-Reposteria',
          'Pañaleras',
          'Peluquerias',
          'Rest Menú diario',
          'Restaurant Menú fij',
          'Restaurantes Gourme',
          'Servicios de Tecn',
          'Soft Discount',
          'Superete Cad Reg',
          'Superete Independ',
          'Supermercado',
          'T Cabecera Barrio',
          'T Estanquilo',
          'Tienda Mixta',
          'Tiendas de Apuestas',
          'Tiendas Mascotas',
          'Tiendas Naturistas'
        ]
      },
      Q2: {
        type: 'string',
        title: 'Años del Negocio',
        required: ['Q2'],
        enum: [
          'Menos de un año a un año',
          'Dos Años',
          'Tres Años',
          'Cuatro Años',
          'Cinco Años',
          'Seis Años',
          'Siete Años',
          'Ocho Años',
          'Nueve Años',
          'Diez Años',
          'Once Años',
          'Doce Años y mas'
        ]
      },
      address: {
        type: 'object',
        title: 'Dirección del punto de venta',
        properties: {
          states: {
            type: 'string',
            title: 'Departamento',
            enum: getRegionList(getRegionMapData())
          }
        },
        dependencies: {
          states: {
            oneOf: getStatesSchema()
          }
        },
        required: ['cities', 'states', 'pathwayType', 'primary', 'add1', 'add2', 'barrio']
      }
    },
    required: [
      'razonSocialNombres',
      'razonSocialApellidos',
      'nombreNegocio',
      'numeroIdentificacion',
      'businessType',
      'Q2',
      'address'
    ]
  },
  uiSchema: {
    'ui:order': [
      'razonSocialNombres',
      'razonSocialApellidos',
      'nombreNegocio',
      'numeroIdentificacion',
      'businessType',
      'Q2',
      'address'
    ],

    businessType: {
      'ui:autocomplete': true
    },
    address: {
      states: {
        'ui:autocomplete': true
      },
      cities: {
        'ui:autocomplete': true
      },
      primary: {
        'ui:placeholder': '12 A',
      },
      add1: {
        'ui:placeholder': '42',
      },
      add2: {
        'ui:placeholder': '12'
      },
      barrio: {
        'ui:placeholder': 'galan'
      }
    }
  }
};
