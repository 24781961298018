/* eslint-disable @typescript-eslint/no-explicit-any */
import FormControl from '@material-ui/core/FormControl';
import TextField, {
  StandardTextFieldProps as TextFieldProps
} from '@material-ui/core/TextField';
import { WidgetProps } from '@rjsf/core';
import React from 'react';

export type TextWidgetProps = WidgetProps & TextFieldProps;

const OutlinedTextField: React.StatelessComponent<TextWidgetProps> = ({
  id,
  required,
  readonly,
  disabled,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
  rawErrors,
  ...textFieldProps
}: TextWidgetProps) => {
  const _onChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void =>
    onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({
    target: { value }
  }: React.FocusEvent<HTMLInputElement>): void => onBlur(id, value);
  const _onFocus = ({
    target: { value }
  }: React.FocusEvent<HTMLInputElement>): void => onFocus(id, value);

  if (['root_address_add1', 'root_address_add2'].includes(id)) {
    const marker: { [key: string]: string } = {
      root_address_add1: '#',
      root_address_add2: '-'
    };

    return (
      <FormControl
        fullWidth={true}
        required={required}
        style={{
          display: 'flex',
          flexDirection: 'unset'
        }}
      >
        <p style={{ width: '10%' }}>{marker[id]}</p>
        <TextField
          variant={'outlined' as any}
          id={id}
          onKeyPress={(e: any) => {
            const isAlphaNumeric = /[a-z 0-9]/i.test(e.key);
            if (!isAlphaNumeric) {
              e.preventDefault();
            }
            return isAlphaNumeric;
          }}
          label={schema.title}
          autoFocus={autofocus}
          required={required}
          disabled={disabled || readonly}
          type={type || (schema.type as string)}
          value={value || value === 0 ? value : ''}
          onChange={_onChange}
          onBlur={_onBlur}
          onFocus={_onFocus}
          error={rawErrors && rawErrors.length > 0}
          {...(textFieldProps as TextFieldProps)}
        />
      </FormControl>
    );
  }

  return (
    <FormControl fullWidth={true} required={required}>
      <TextField
        variant={'outlined' as any}
        id={id}
        label={schema.title}
        autoFocus={autofocus}
        required={required}
        disabled={disabled || readonly}
        type={type || (schema.type as string)}
        value={value || value === 0 ? value : ''}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        error={rawErrors && rawErrors.length > 0}
        {...(textFieldProps as TextFieldProps)}
      />
    </FormControl>
  );
};

export default OutlinedTextField;
