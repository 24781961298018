import {
  Authenticator,
  Button,
  Heading,
  Image,
  Text,
  translations,
  useAuthenticator,
  useTheme,
  View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, I18n } from 'aws-amplify';
import awsExports from '../aws-exports';
import { CognitoUser } from "../models/CognitoUser";
import { parseCognitoData } from '../utils/cognitoData';
import AppForm from './components/AppForm';
import AppCondition from './components/ConditionForm';
I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Sign In': 'Registrarse',
    'Sign Up': 'Regístrate'
  }
});

Amplify.configure(awsExports);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image
          alt="Amplify logo"
          src="/assets/nutresa_logo.png"
          style={{ width: 200, marginBottom: 15 }}
        />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();
      const { toFederatedSignIn } = useAuthenticator();

      return (
        <View style={{ paddingRight: 32, paddingLeft: 32 }}>
          <Heading padding={`${tokens.space.xl}`} level={3}>
            Ingrese a su cuenta
          </Heading>
          {/* Button background color should be #0056b4 but form button colors must be changed on AWS*/}
          <Button
            justifyContent="normal"
            width="100%"
            fontWeight="bold"
            backgroundColor="#047d95"
            onClick={() => toFederatedSignIn({ provider: 'SSO' })}
          >
            <Text
              textAlign="center"
              width="100%"
              paddingRight={24}
              color="#fff"
            >
              Iniciar sesión como Colaborador
            </Text>
          </Button>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <hr style={{ margin: '0px', width: '40%' }} />
            <p> O </p>
            <hr style={{ margin: '0px', width: '40%' }} />
          </div>
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Cambiar Contraseña
          </Button>
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Crear una cuenta nueva
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    }
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    }
  }
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: 'Ingrese su usuario'
    }
  },
  signUp: {
    password: {
      labelHidden: false,
      label: 'Clave:',
      placeholder: 'Ingrese su clave',
      isRequired: false,
      order: 1
    },
    confirm_password: {
      labelHidden: false,
      label: 'Confirmar clave',
      order: 2
    }
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your Password:'
    }
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'username'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter your Confirmation Code:',
      label: 'New Label',
      isRequired: false
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Enter your Password Please:'
    }
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user'
    },
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false
    }
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: 'New Label',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false
    }
  }
};

export default function Dashboard(): React.JSX.Element {
  return (
    <Authenticator
      hideSignUp={true}
      formFields={formFields}
      components={components}
    >

      {({ signOut, user }) => {
        if (user) {
          const user_data = user as any
          const parsedSellerInfo = parseCognitoData(user_data as CognitoUser)
          console.log('Parámetro condicional')
          console.log(parsedSellerInfo.conditions, parsedSellerInfo.date)
          if (parsedSellerInfo.conditions == 'True') {
            return <AppForm user={user} handleLogout={signOut} />
          }
          else {
            return <AppCondition user={user} handleLogout={signOut} />
          }

        }
      }}
    </Authenticator>
  );
}
