/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Autocomplete } from '@material-ui/lab';
import { WidgetProps } from '@rjsf/core';
import React, { useState } from 'react';
import { processValue } from './../../utils';

const OutlinedSelect = ({
  schema,
  id,
  options,
  label,
  required,
  disabled,
  readonly,
  value,
  multiple,
  autofocus,
  onChange,
  onBlur,
  onFocus
}: WidgetProps): JSX.Element => {
  const { enumOptions, enumDisabled } = options;
  const emptyValue = multiple ? [] : '';

  const _onChange = ({
    target: { value }
  }: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    onChange(processValue(schema, value));
  };
  const _onBlur = ({
    target: { value }
  }: React.FocusEvent<HTMLInputElement>): void =>
    onBlur(id, processValue(schema, value));

  const _onFocus = ({
    target: { value }
  }: React.FocusEvent<HTMLInputElement>): void =>
    onFocus(id, processValue(schema, value));

  const [autocompleteValue, setAutocompleteValue] = useState<string | null>('');

  return (
    <FormControl variant={'outlined' as any} required={required}>
      {options?.autocomplete ? (
        <Autocomplete
          id={id}
          value={autocompleteValue}
          onChange={(event: any, newAutocompleteValue: string | null) => {
            onChange(newAutocompleteValue || '');
            setAutocompleteValue(newAutocompleteValue);
          }}
          getOptionLabel={(option: any) => option}
          getOptionSelected={(option, value) => option === value}
          options={(enumOptions as any).map((option: any) => option.label)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label || schema.title}
              variant="outlined"
              value={autocompleteValue}
              autoFocus={autofocus}
              required={required}
              disabled={disabled || readonly}
            />
          )}
        />
      ) : (
        <>
          <InputLabel shrink={true} htmlFor={id} id={`input-label-${id}`}>
            {label || schema.title}
          </InputLabel>
          <Select
            id={id}
            labelId={`input-label-${id}`}
            multiple={typeof multiple === 'undefined' ? false : multiple}
            value={typeof value === 'undefined' ? emptyValue : value}
            required={required}
            disabled={disabled || readonly}
            autoFocus={autofocus}
            onChange={_onChange}
            onBlur={_onBlur}
            onFocus={_onFocus}
            label={label || schema.title}
          >
            {(enumOptions as any).map(({ value, label }: any, i: number) => {
              const disabled: any =
                enumDisabled && (enumDisabled as any).indexOf(value) !== -1;
              return (
                <MenuItem key={i} value={value} disabled={disabled}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default OutlinedSelect;
