import { JSONSchema7 } from 'json-schema';
import { RegionCity, RegionMap } from '../../models/Region';
import regionMapData from './data/regions.json';

export function getRegionMapData(): RegionMap {
  return regionMapData as RegionMap;
}

export function getCitiesByRegion(
  region: string,
  regionMap: RegionMap
): string[] {
  return regionMap[region]
    ? regionMap[region].cities.map((city: RegionCity) => city.name)
    : [];
}

export function getRegionList(regionMap: RegionMap | null): string[] {
  if (!regionMap) {
    return [];
  }
  return Object.keys(regionMap);
}

export function getStatesSchema(): JSONSchema7[] {
  const regionKeys = getRegionList(getRegionMapData());
  return regionKeys.map((regionName: string) => {
    return {
      properties: {
        states: {
          const: regionName
        },
        cities: {
          type: 'string',
          title: 'Municipio',
          enum: getCitiesByRegion(regionName, regionMapData)
        },
        pathwayType: {
          type: 'string',
          title: 'Tipo de Vía',
          enum: [
            'Anillo',
            'Autopista',
            'Avenida',
            'Avenida Calle',
            'Avenida Carrera',
            'Boulevar',
            'Calle',
            'Camino',
            'Carrera',
            'Carretera',
            'Circular',
            'Circunvalar',
            'Corregimiento',
            'Diagonal',
            'Kilometro',
            'Sector',
            'Transversal',
            'Variante',
            'Vereda',
            'Vía',
            'Manzana'
          ]
        },
        primary: {
          type: 'string',
          title: ''
        },
        add1: {
          type: 'string',
          title: ''
        },
        add2: {
          type: 'string',
          title: ''
        },
        complement: {
          type: 'string',
          title: 'Complemento de la dirección'
        },
        barrio: {
          type: 'string',
          title: 'Barrio'
        }
      }
    };
  });
}
