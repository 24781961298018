import { AxiosInstance } from 'axios';
import {
  AppModelGetResultRequest,
  AppModelGetResultResponse,
  AppRunModelRequest,
  AppRunModelResponse,
  AppSaveResultRequest,
  AppSaveResultResponse
} from '../../models';
import Config from '../config';
export default class ModelApiService {
  constructor(private axios: AxiosInstance) { }

  public async getResults(
    modelRequest: AppModelGetResultRequest
  ): Promise<AppModelGetResultResponse | null> {
    try {
      /* istanbul ignore else */
      if (!modelRequest.version) {
        modelRequest.version = Config.ModelResult.version;
      }
      const response = await this.axios.post<AppModelGetResultResponse>(
        Config.ModelResult.endpoint,
        modelRequest
      );
      if (!response || !response.data) return null;
      return response.data;
    } catch (e: unknown) {
      return null;
    }
  }

  public async saveResults(
    data: AppSaveResultRequest
  ): Promise<AppSaveResultResponse> {
    try {
      const response = await this.axios.post<AppSaveResultResponse>(
        Config.SaveResult.endpoint,
        data
      );
      if (!response || !response.data)
        return {
          success: false,
          message:
            'Servicio no disponible. No hubo respuesta por parte del servidor.'
        };
      return response.data;
    } catch (e: unknown) {
      return {
        success: false,
        message: `Servicio no disponible. Error: ${(e as Error).message}`
      };
    }
  }

  public async runModel(
    modelRequest: AppRunModelRequest
  ): Promise<AppRunModelResponse> {
    /* istanbul ignore else */
    if (Config.ModelExecutor.data) {
      modelRequest.ruta_cliente_mes =
        Config.ModelExecutor.data['ruta_cliente_mes'];
      modelRequest.s3_path_model = Config.ModelExecutor.data['s3_path_model'];
    }
    try {
      this.axios.post<AppRunModelResponse>(
        Config.ModelExecutor.endpoint,
        modelRequest,
        {
          headers: {
            'X-Api-Key': Config.ModelExecutor.token
          }
        }
      );
      return {
        success: true
      };
    } catch (e: unknown) {
      return {
        success: false,
        message: `Servicio no disponible. Error: ${(e as Error).message}`
      };
    }
  }
}
