import { makeStyles } from '@material-ui/core/styles';
import { NutresaColors } from './colors';
const appFormStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: NutresaColors.Primary,
    color: '#ffff'
  },
  appBarLogo: {
    width: '30px',
    marginRight: theme.spacing(2)
  },
  CardForm: {
    display: 'block',
    margin: 'auto',
    paddingTop: theme.spacing(4)
  },
  container: {
    display: 'flex',
    width: '100%',
    margin: `${theme.spacing(0)} auto`
  },
  submittingWrapper: {
    position: 'relative'
  },
  submittingButton: {
    marginLeft: `10px`,
    color: 'white',
    backgroundColor: NutresaColors.Primary,
    '&:hover': {
      background: NutresaColors.Primary
    }
  },
  submittingButtonTerms: {
    margin: '0 auto 3rem',
    marginTop: '10px',
    color: 'white',
    backgroundColor: NutresaColors.Primary,
    display: 'block',
    '&:hover': {
      background: NutresaColors.Primary
    }
  },
  exportButton: {
    border: `1px solid ${NutresaColors.Primary}`,
    color: NutresaColors.Primary
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2,
    background: NutresaColors.Primary,
    color: 'white'
  },
  text: {
    marginTop: 0
  }
}));

export default appFormStyles;
