import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AjvError, WidgetProps } from '@rjsf/core';
import Form from '@rjsf/material-ui';
import { validate } from 'json-schema';
import React, { StatelessComponent, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModelApiService } from '../../api';
import { Messages } from '../../i18n';
import { AppFormData, AppRunModelResponse, RJSFSchema } from '../../models';
import { transformErrors } from '../../utils/errors';
import DataResult from '../DataFormResult/DataResult';
import appStyles from './../../styles/appFormStyles';
import {
  getAddress,
  getAddressWithComplement,
  getCurrentDate,
  getFormRequestId
} from './../../utils';
import OutlinedSelect from './OutlinedSelect';
import OutlinedTextField from './OutlinedTextField';

interface CardFormFromUniformProps {
  title?: string;
  schemas: RJSFSchema[];
  formData: AppFormData;
  destinationUrl?: string;
  readOnly?: boolean;
}

export default function CardFormFromUniform({
  title,
  schemas,
  formData,
  readOnly
}: CardFormFromUniformProps): JSX.Element {
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isCurrentFormPageValid, setCurrentFormPageValid] = useState(true);
  const [currentSchema, setCurrentSchema] = useState<RJSFSchema>(schemas[0]);

  const [status, setStatus] = useState({
    success: false,
    message: ''
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [currentFormData, setCurrentFormData] = useState<AppFormData>(formData);
  const classes = appStyles();

  const [dataResultShow, setDataResultShow] = useState(false);

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    if (formData) setCurrentFormData(formData);
  }, [formData]);

  useEffect(() => {
    setCurrentSchema(schemas[currentStep]);
    if (currentStep === 0) getGeoReference();
  }, [schemas, currentStep]);

  const getGeoReference = async () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  };

  const handleRunModel = async (
    formDataResult: AppFormData
  ): Promise<AppRunModelResponse> => {
    return ModelApiService.runModel({
      requestId: formDataResult.formRequestId || '',
      direc: formDataResult.address?.addressName || '',
      ciudad: `${formDataResult.address?.cities} ${formDataResult.address?.states}`,
      Tipología: formDataResult.businessType || '',
      lat: latitude || 0,
      lon: longitude || 0
    });
  };

  const validateAddressProperty = (): boolean => {
    if (
      currentFormData?.address?.pathwayType == undefined ||
      currentFormData?.address?.primary == undefined ||
      currentFormData?.address?.add1 == undefined ||
      currentFormData?.address?.add2 == undefined ||
      currentFormData?.address?.barrio == undefined
    ) {
      return false;
    }
    return true;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNext = async (e: any) => {
    if (!validate(currentFormData as never, currentSchema.schema).valid) {
      setCurrentFormPageValid(false);
      return;
    }

    if (currentStep === schemas.length - 1) {
      setCurrentFormPageValid(true);
      return handleSubmit(e);
    }

    if (currentStep === 0) {
      const addressValidation = validateAddressProperty();
      if (!addressValidation) {
        setCurrentFormPageValid(false);
        return;
      }
      currentFormData.formRequestId = getFormRequestId(currentFormData);

      if (currentFormData.address) {
        currentFormData.address.latitude = latitude;
        currentFormData.address.longitude = longitude;
        currentFormData.address.addressName = getAddress(currentFormData);
        currentFormData.address.fullAddress =
          currentFormData.address.complement == undefined
            ? currentFormData.address.addressName
            : getAddressWithComplement(currentFormData);
      }

      const modelResponse = await handleRunModel(currentFormData);
      if (!modelResponse.success) {
        alert(modelResponse.message);
        return;
      }

      setCurrentFormData(currentFormData);
    }

    setCurrentFormPageValid(true);
    setCurrentStep(currentStep + 1);
    setCurrentSchema(schemas[currentStep]);
  };

  const handlePrev = () => {
    setCurrentFormPageValid(true);
    setCurrentStep(currentStep - 1);
    setCurrentSchema(schemas[currentStep]);
  };

  const handleSubmit = (event: React.FormEvent<AppFormData>) => {
    currentFormData.generationDate = getCurrentDate();

    event.preventDefault();
    setSubmitting(true);

    setDataResultShow(true);
    setSubmitting(false);
    setCurrentFormData(currentFormData);

    setStatus({
      success: true,
      message: ''
    });
  };

  return (
    <Card>
      {title && (
        <CardHeader
          title={`${title} - ${currentSchema.schema.title}`}
          style={{ padding: '20px' }}
        />
      )}
      <Typography
        variant="subtitle1"
        component="div"
        style={{ paddingLeft: '20px' }}
      >
        Vendedor: {formData.seller}
      </Typography>
      <Divider />
      <CardContent>
        <Form
          id="appForm"
          showErrorList={false}
          noHtml5Validate={true}
          schema={currentSchema.schema}
          widgets={{
            TextWidget: OutlinedTextField as StatelessComponent<WidgetProps>,
            SelectWidget: OutlinedSelect as StatelessComponent<WidgetProps>
          }}
          formData={currentFormData}
          onChange={(e) => setCurrentFormData(e.formData)}
          uiSchema={currentSchema.uiSchema}
          transformErrors={(e): AjvError[] =>
            transformErrors(e, isCurrentFormPageValid)
          }
        >
          {isAlertVisible && status && (
            <Box mb={3}>
              <Alert
                onClose={(): void => setAlertVisible(false)}
                severity={status.success ? 'success' : 'error'}
              >
                {status.message}
              </Alert>
            </Box>
          )}
          <Box mt={2} className={`${classes.box}`}>
            {currentStep > 0 && (
              <Button
                className={classes.submittingButton}
                size="large"
                type="submit"
                variant="contained"
                disabled={readOnly}
                onClick={handlePrev}
              >
                {Messages.PAGINATION_BACK}
              </Button>
            )}
            <Button
              className={classes.submittingButton}
              size="large"
              type="submit"
              variant="contained"
              disabled={readOnly}
              onClick={handleNext}
              startIcon={
                isSubmitting && <CircularProgress size={18} color="inherit" />
              }
            >
              {currentStep === schemas.length - 1
                ? isSubmitting
                  ? Messages.FORM_SUBMITTING
                  : Messages.FORM_SUBMIT
                : Messages.PAGINATION_NEXT}
            </Button>
          </Box>
        </Form>
      </CardContent>
      {dataResultShow && (
        <DataResult
          show={dataResultShow}
          formData={currentFormData}
        ></DataResult>
      )}
      <ToastContainer />
    </Card>
  );
}
