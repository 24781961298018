import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  SvgIcon
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { FileCopyOutlined } from '@material-ui/icons';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { useEffect, useState } from 'react';
import { ModelApiService } from '../../api';
import { Messages } from '../../i18n';
import {
  AppFormData,
  AppFormMapping,
  AppRunModelResponse,
  IAppFormMapping,
  ObjectMap
} from '../../models';
import { ModelResultLabel } from '../../models/ModelResultLabel';
import appFormStyles from '../../styles/appFormStyles';
import { getCurrentDate } from '../../utils';
import GeneratePDF from '../ExportFormData/GeneratePDF';
import dataScoreRange from './dataScoreRanges';

interface DataResultProps {
  show: boolean;
  formData: AppFormData;
}

export interface ModelResult {
  firstRequest: boolean;
  available: boolean;
  result: number;
  hasError: boolean;
  additionalData: string;
  message: string;
}

export default function DataResult({
  show,
  formData
}: DataResultProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [modelResult, setResultsAvailable] = useState<ModelResult>({
    firstRequest: true,
    available: false,
    result: 0,
    hasError: false,
    additionalData: '',
    message: 'Calculando resultados... por favor espere.'
  });
  const [modelResultLabel, setResults] = useState<ModelResultLabel | null>(
    null
  );

  const [waitResultTimer, setWaitResultTimer] = useState(60);
  const [waitResultMins, setWaitResultMins] = useState(0);

  const [percentageScore, setScore] = useState<number>(0);
  const classes = appFormStyles();

  useEffect(() => {
    const myInterval = setInterval(() => {
      const elapsedMins =
        waitResultTimer > 0 ? waitResultMins : waitResultMins + 1;
      if (waitResultTimer === 0 && elapsedMins !== 5) {
        getModelResult();
      }

      if (!modelResult.available && !modelResult.hasError) {
        setWaitResultTimer(waitResultTimer > 0 ? waitResultTimer - 1 : 60);
        setWaitResultMins(elapsedMins);
        if (elapsedMins === 5) {
          const noResMsg = `- La solicitud debe hacerse de forma local desde el punto de venta.
          - La conexión de la red debe ser continua y estable al momento de generar la consulta en el aplicativo.
          `;
          setResultsAvailable({
            firstRequest: false,
            additionalData: noResMsg,
            hasError: true,
            available: false,
            result: 0,
            message: '⚠️ NO FUE POSIBLE OBTENER LOS RESULTADOS ⚠️'
          });
          clearInterval(myInterval);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [waitResultTimer, modelResult]);

  const calculatePercentageScore = (scoreResult: number) => {
    const percentageScore = scoreResult === 0 ? scoreResult : scoreResult * 100;
    const percentageResults =
      scoreResult === 0
        ? dataScoreRange.find(
          (dataScore) =>
            percentageScore === dataScore.range.min &&
            percentageScore === dataScore.range.max
        )
        : dataScoreRange.find(
          (dataScore) =>
            percentageScore > dataScore.range.min &&
            percentageScore <= dataScore.range.max
        );
    const dataResult = {
      percentageScore: parseFloat(percentageScore.toFixed(2)),
      percentageResults
    };

    return dataResult;
  };

  const handleSaveResults = async (
    formDataResult: AppFormData,
    score: number
  ): Promise<AppRunModelResponse> => {
    const formDataMapped: IAppFormMapping[] = [];

    for (const formDataKey of Object.keys(AppFormMapping)) {
      const answer = (formDataResult as unknown as ObjectMap<string>)[
        formDataKey
      ];
      const title = AppFormMapping[formDataKey];

      formDataMapped.push({
        title,
        answer
      });
    }

    return ModelApiService.saveResults({
      requestId: formDataResult.formRequestId,
      seller: formDataResult.sellerInfo,
      date: getCurrentDate(),
      modelScore: score,
      data: formDataMapped
    });
  };

  const getModelResult = async () => {
    const modelResponse = await ModelApiService.getResults({
      requestId: formData.formRequestId || ''
    });

    if (!modelResponse) {
      setResultsAvailable({
        firstRequest: false,
        additionalData: '',
        hasError: false,
        available: false,
        result: 0,
        message: 'Calculando resultados... por favor espere.'
      });
    } else {
      if (!modelResponse.success) {
        setResultsAvailable({
          firstRequest: false,
          additionalData: modelResponse?.additionalData || '',
          hasError: true,
          available: false,
          result: modelResponse?.modelResult || 0,
          message:
            modelResponse?.reason || 'No fue posible obtener los resultados.'
        });
      }
      if (!modelResponse.data.Success) {
        await handleSaveResults(formData, 0);
        const { percentageScore, percentageResults } =
          calculatePercentageScore(0);

        setScore(percentageScore);
        if (percentageResults) setResults(percentageResults);
        setResultsAvailable({
          firstRequest: false,
          additionalData: modelResponse?.data?.Message,
          hasError: true,
          available: true,
          result: 0,
          message: 'No fue posible obtener los resultados.'
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (modelResponse.success && (modelResponse as any).data.Success) {
        await handleSaveResults(formData, modelResponse?.modelResult);
        const { percentageScore, percentageResults } = calculatePercentageScore(
          modelResponse?.modelResult
        );

        setScore(percentageScore);
        if (percentageResults) setResults(percentageResults);

        setResultsAvailable({
          firstRequest: false,
          additionalData: '',
          message: 'Resultados del estudio de punto',
          available: true,
          hasError: false,
          result: modelResponse.modelResult
        });
      }
    }
  };

  const handleClose = () => {
    window.location.reload();
    setOpen(false);
  };

  useEffect(() => {
    if (show) setOpen(true);
    if (
      !modelResult.available &&
      !modelResult.hasError &&
      modelResult.firstRequest
    ) {
      getModelResult();
    }
  }, [show, modelResult]);

  if (modelResultLabel) {
    formData.dataScore = { ...modelResultLabel, percentageScore };
  }

  return (
    <>
      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={classes.header}
          style={{ padding: '1.5em' }}
        >
          Niveles de resultado del nuevo Estudio de Punto.
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#F9F9FB', padding: '2em' }}>
          <DialogContentText
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontSize: '1em',
              marginBottom: '2em'
            }}
          >
            {modelResult.message}
          </DialogContentText>
          <Grid container spacing={3}>
            {modelResultLabel && modelResult.available ? (
              <>
                {modelResultLabel.icon && (
                  <Grid item xs={2}>
                    <SvgIcon
                      component={modelResultLabel.icon}
                      style={{
                        fontSize: '2.5em',
                        color: modelResultLabel.iconColor
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={9}>
                  <DialogContentText>
                    {modelResult.additionalData != ''
                      ? modelResult.additionalData
                      : modelResultLabel.label}
                  </DialogContentText>
                  <DialogContentText
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    Id: {formData.formRequestId}{' '}
                    <button
                      style={{ display: 'flex', marginLeft: '1rem' }}
                      color="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(formData.formRequestId);
                      }}
                    >
                      <FileCopyOutlined></FileCopyOutlined>
                    </button>
                  </DialogContentText>
                  <DialogContentText style={{ fontWeight: 'bold' }}>
                    Resultado modelo: {percentageScore}
                  </DialogContentText>
                </Grid>
              </>
            ) : modelResult.hasError ? (
              modelResult.additionalData.split('\n').map((line, index) => {
                return (
                  <p className={classes.text} key={index}>
                    {line}
                  </p>
                );
              }) || ''
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {modelResult.available && (
            <Button
              color="primary"
              startIcon={<PdfIcon />}
              onClick={() => GeneratePDF(formData, modelResult, true)}
            >
              {Messages.EXPORT_FORM_DATA}
            </Button>
          )}

          {/* <Button onClick={getModelResult} color="primary" autoFocus>
            Validar resultado.
          </Button> */}

          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
