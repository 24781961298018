import { CognitoUser } from "../models/CognitoUser";
import { SellerInfo } from "../models/User";

export function parseCognitoData(cognitoUser: CognitoUser): SellerInfo {
    const parsedCognitoData: SellerInfo = {
        active: cognitoUser.attributes.email_verified,
        displayName: cognitoUser.username,
        email: cognitoUser.attributes.email,
        name: cognitoUser.username,
        sellerCode: cognitoUser.attributes["custom:Codigo_Vendedor"],
        conditions: cognitoUser.attributes["custom:Terminos_Condiciones"],
        date: cognitoUser.attributes["custom:Fecha_Terminos"],
        userId: ''
    };
    return parsedCognitoData;
}