import { RJSFSchema } from '../../../models/RJSFSchema';
/**
 * Location and surroundings schema
 */
export const LocationSchema: RJSFSchema = {
  schema: {
    type: 'object',
    title: 'Ubicación y Alrededores',
    properties: {
      Q14: {
        required: ['Q14'],
        type: 'string',
        title:
          '¿Existen cerca (100 mts) otros negocios en donde hayan congeladores diferentes a CREM HELADO?',
        enum: ['Si', 'No']
      },
      Q15: {
        required: ['Q15'],
        type: 'string',
        title: 'El congelador de CREM HELADO estaria ubicado:',
        enum: [
          'Por fuera del Negocio',
          'Mitad adentro y mitad afuera del negocio',
          'Dentro del Negocio',
          'Adentro del Mostrador del Negocio'
        ]
      },
      Q16: {
        required: ['Q16'],
        type: 'string',
        title:
          '¿El consumidor puede observar directamente los productos de CREM HELADO?',
        enum: ['Si', 'No', 'No aplica']
      }
    },
    required: ['Q14', 'Q15', 'Q16']
  },
  uiSchema: {
    'ui:order': ['Q14', 'Q15', 'Q16']
  }
};
