import { RJSFSchema } from '../../models/RJSFSchema';
import {
  EstablishmentSchema,
  GeneralDataSchema,
  LocationSchema
} from './schemas';

export function getFormSchemas(): RJSFSchema[] {
  return [GeneralDataSchema, LocationSchema, EstablishmentSchema];
}
